import { store } from 'react-notifications-component';
import { continents } from 'countries-list';
import _cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { fromNullable } from './fp_utils';
import i18n from './I18n/I18n';

dayjs.extend(localizedFormat);

const addNotification = ({ type, title, message }) => {
  store.addNotification({
    type,
    title,
    message,
    width: 300,
    insert: 'bottom',
    container: 'bottom-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
    },
  });
};

const success = (message) => {
  addNotification({ type: 'success', title: '', message });
};
const failed = (message, title) => {
  addNotification({
    type: 'danger',
    title: title || i18n.t('message.errorTryAgain'),
    message,
  });
};

function buildTreeStructure(collection = []) {
  const clonedCollection = _cloneDeep(collection);

  return function treeBuilder(parent = {}, KEY) {
    if (!parent.label && !parent.value) {
      parent.label = parent.name;
      parent.value = parent.id;
    }
    const parentsDirectChildren = clonedCollection.filter(
      element => element[KEY] === parent.id,
    );
    const updatedElementObj = {
      ...parent,
      children: parentsDirectChildren,
    };
    updatedElementObj.children.forEach((child) => {
      child.children = [...treeBuilder(child, KEY).children];
    });
    return updatedElementObj;
  };
}
const langToLang = _map => (str = '') => String(str)
  .split('')
  .map(c => _map[c] || c)
  .join('');
const transformDigitsToEnglish = langToLang({
  '١': '1',
  '٢': '2',
  '٣': '3',
  '٤': '4',
  '٥': '5',
  '٦': '6',
  '٧': '7',
  '٨': '8',
  '٩': '9',
  '٠': '0',
});
const transformDigitsToArabic = langToLang({
  1: '١',
  2: '٢',
  3: '٣',
  4: '٤',
  5: '٥',
  6: '٦',
  7: '٧',
  8: '٨',
  9: '٩',
  0: '٠',
});
const statusesMap = {
  patchColor: {
    pendingMinistry: 'yellow',
    pendingCitizen: 'cyan',
    closed: 'gray',
  },
  statusText: {
    pendingMinistry: i18n.t('message.waitingMinistryResponse'),
    pendingCitizen: i18n.t('message.waitingCitizenResponse'),
    closed: i18n.t('message.closed'),
  },
};

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const years = [{ value: 1900, label: 1900 }];
let i = 0;
for (i = 0; years[years.length - 1].value < currentYear; i += 1) {
  years.push({
    value: years[years.length - 1].value + 1,
    label: years[years.length - 1].value + 1,
  });
}
const continentsList = Object.entries(continents)
  .map(z => ({ label: z[1], value: z[0] }))
  .unshift({ value: 'all', label: 'world', labelAr: 'الكل' });

const continentsListArabic = [
  { value: 'all', label: 'world', labelAr: 'الكل' },
  { value: 'AF', label: 'africa', labelAr: 'أفريقيا' },
  { value: 'AS', label: 'asia', labelAr: 'آسيا' },
  { value: 'EU', label: 'europe', labelAr: 'أوروبا' },
  { value: 'NA', label: 'north america', labelAr: 'أمريكا الشمالية' },
  { value: 'SA', label: 'south america', labelAr: 'أمريكا الجنوبية' },
  { value: 'OC', label: 'oceania', labelAr: 'أوقيانوسيا' },
  { value: 'OT', label: 'other', labelAr: 'أخرى' },
];

export const generateResourceURL = (path, fallback = '/placeholder.png') => {
  const baseUrl = process.env.REACT_APP_IMAGES_URL;
  if (!path) {
    return fallback;
  }

  if (baseUrl.includes('assets')) {
    return `${baseUrl.replace(
      '/api/containers/files/download',
      '/files/',
    )}${path}`;
  }

  // Check if the path is a complete URL or needs the base URL prepended
  if (path.startsWith('/api/containers/files/download/')) {
    return `${baseUrl}${path.replace('/api/containers/files/download', '')}`;
  }

  // Handle cases where only the file name is provided
  return `${baseUrl}/${path}`;
};

const concatServerURL = path => `${process.env.REACT_APP_BACKEND_URL}${path}`;
const generatePathWithServerURL = (path, fallbackPath) => fromNullable(path)
  .map(p => concatServerURL(p))
  .fold(
    p => fallbackPath || concatServerURL(p),
    p => p,
  );
const formatDate = format => date => dayjs(date).format(format);

const getNavLinkForManageStructure = (menu, lang) => {
  const titleMap = {
    ar: 'عن الوزارة',
    en: 'About',
  };
  const linkTitleMap = {
    ar: 'الهيكل الإدارى',
    en: 'Administrative Structure',
  };
  const aboutMinistryMenuItem = menu.find(
    ({ title }) => title === titleMap[lang],
  );
  if (!aboutMinistryMenuItem) return undefined;
  const { children: [firstChild] = [] } = aboutMinistryMenuItem;
  if (!firstChild) return undefined;
  const { children = [] } = firstChild ?? {};
  const { customLink } = children.find(
    ({ title }) => title === linkTitleMap[lang],
  );
  return customLink;
};

export {
  formatDate,
  generatePathWithServerURL,
  concatServerURL,
  continentsListArabic,
  continentsList,
  years,
  statusesMap,
  transformDigitsToEnglish,
  transformDigitsToArabic,
  buildTreeStructure,
  failed,
  success,
  getNavLinkForManageStructure,
};
